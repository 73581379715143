const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const appManage = [{
    path: '/app/rule/module',
    name: 'appModule',
    meta: {
      auth: true,
      cache: true,
      title: '模块管理'
    },
    component: _import('page/appManage/appModule')
  },
  {
    path: '/ver',
    name: 'ver',
    meta: {
      auth: true,
      cache: true,
      title: '版本管理'
    },
    component: _import('page/appManage/ver')
  },
  {
    path: '/app/agreement',
    name: 'agreement',
    meta: {
      auth: true,
      cache: true,
      title: 'app协议管理'
    },
    component: _import('page/appManage/agreement')
  },
  {
    path: '/config',
    name: 'config',
    meta: {
      auth: true,
      cache: true,
      title: '配置文件管理'
    },
    component: _import('page/appManage/config')
  },
  {
    path: '/banner',
    name: 'banner',
    meta: {
      auth: true,
      cache: true,
      title: '广告位管理'
    },
    component: _import('page/appManage/banner')
  },
  {
    path: '/operationRecord',
    name: 'operationRecord',
    meta: {
      auth: true,
      cache: true,
      title: '操作记录'
    },
    component: _import('page/appManage/operationRecord')
  }
]
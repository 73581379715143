const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const appUserManage = [{
    path: '/app/user',
    name: 'appUser',
    meta: {
      auth: true,
      cache: true,
      title: '用户数据'
    },
    component: _import('page/appUserManage/appUser')
  },

  {
    path: '/app/rule/role',
    name: 'appRole',
    meta: {
      auth: true,
      cache: true,
      title: '角色管理'
    },
    component: _import('page/appUserManage/appRole')
  },
  {
    path: '/app/rule/route',
    name: 'appRoute',
    meta: {
      auth: true,
      cache: true,
      title: '节点管理'
    },
    component: _import('page/appUserManage/appRoute')
  },
  {
    path: '/app/rule/team',
    name: 'appTeam',
    meta: {
      auth: true,
      cache: true,
      title: '团队管理'
    },
    component: _import('page/appUserManage/appTeam')
  },
  {
    path: '/vip',
    name: 'vip',
    meta: {
      auth: true,
      cache: true,
      title: '会员管理'
    },
    component: _import('page/appUserManage/appVip.vue')
  },
  {
    path: '/memberShipManage',
    name: 'memberShipManage',
    meta: {
      auth: true,
      title: '会员权益管理'
    },
    component: _import('page/appUserManage/appMemberShipManage.vue')
  },
  {
    path: '/channel',
    name: 'channel',
    meta: {
      auth: true,
      cache: true,
      title: '渠道管理'
    },
    component: _import('page/appUserManage/channel')
  },
  {
    path: '/app/mcc',
    name: 'appMcc',
    meta: {
      auth: true,
      cache: true,
      title: '绑定mcc人员'
    },
    component: _import('page/appUserManage/appMcc')
  },
  {
    path: '/emailMarket',
    name: 'emailMarket',
    meta: {
      auth: true,
      cache: true,
      title: '邮件计划'
    },
    component: _import('page/appUserManage/emailMarket')
  },

]
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const store = [
    {
        path: '/commodity/management',
        name: 'commodityManagement',
        meta: {
            auth: true,
            cache: true,
            title: '商品管理'
        },
        component: _import('page/store/commodityManagement')
    },
    {
        path: '/complaint/management',
        name: 'complaintManagement',
        meta: {
            auth: true,
            cache: true,
            title: '投诉管理'
        },
        component: _import('page/store/complaintManagement')
    }, {
        path: '/evaluation/management',
        name: 'evaluationManagement',
        meta: {
            auth: true,
            cache: true,
            title: '评价管理'
        },
        component: _import('page/store/evaluationManagement')
    }, {
        path: '/service/order',
        name: 'serviceOrder',
        meta: {
            auth: true,
            cache: true,
            title: '服务订单'
        },
        component: _import('page/store/serviceOrder')
    }
]

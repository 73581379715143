const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const operate = [
  {
    path: "/operate/feedback",
    name: "feedback",
    meta: {
      auth: true,
      cache: true,
      title: "意见反馈",
    },
    component: _import("page/operate/feedback"),
  },
  {
    path: "/operate/serviceCollectionRecord",
    name: "serviceCollectionRecord",
    meta: {
      auth: true,
      cache: true,
      title: "服务发放记录",
    },
    component: _import("page/operate/serviceCollectionRecord"),
  },
  {
    path: "/operate/enterpriseChannel",
    name: "enterpriseChannel",
    meta: {
      auth: true,
      cache: true,
      title: "企业渠道库",
    },
    component: _import("page/operate/enterpriseChannel"),
  },
  {
    path: "/operate/enterprise",
    name: "enterprise",
    meta: {
      auth: true,
      cache: true,
      title: "企业库",
    },
    component: _import("page/operate/enterprise"),
  },
  {
    path: "/operate/serviceContractLibrary",
    name: "serviceContractLibrary",
    meta: {
      auth: true,
      cache: true,
      title: "服务合同库",
    },
    component: _import("page/operate/serviceContractLibrary"),
  },
  {
    path: "/operate/serviceLibrary",
    name: "serviceLibrary",
    meta: {
      auth: true,
      cache: true,
      title: "服务库",
    },
    component: _import("page/operate/serviceLibrary"),
  },
  {
    path: "/operate/coupon",
    name: "coupon",
    meta: {
      auth: true,
      cache: true,
      title: "优惠券管理",
    },
    component: _import("page/operate/coupon"),
  },
  {
    path: "/operate/code",
    name: "operate.code",
    meta: {
      auth: true,
      cache: true,
      title: "用户邀请码",
    },
    component: _import("page/operate/code"),
  },
  {
    path: "/operate/contract",
    name: "contract",
    meta: {
      auth: true,
      cache: true,
      title: "电子合同模板",
    },
    component: _import("page/operate/contract"),
  },
  {
    path: "/operate/contractAccount",
    name: "contractAccount",
    meta: {
      auth: true,
      cache: true,
      title: "电子合同账户",
    },
    component: _import("page/operate/contractAccount"),
  },
  {
    path: "/operate/groupManage",
    name: "groupManage",
    meta: {
      auth: true,
      cache: true,
      title: "分组管理",
    },
    component: _import("page/operate/groupManage"),
  },
  {
    path: "/operate/groupUserDetail",
    name: "groupUserDetail",
    meta: {
      auth: true,
      cache: true,
      title: "分组用户",
    },
    component: _import("page/operate/groupUserDetail"),
  },
  {
    path: "/operate/groupTeamDetail",
    name: "groupTeamDetail",
    meta: {
      auth: true,
      cache: true,
      title: "分组团队",
    },
    component: _import("page/operate/groupTeamDetail"),
  },
  {
    path: "/operate/activityManage",
    name: "activityManage",
    meta: {
      auth: true,
      cache: true,
      title: "活动管理",
    },
    component: _import("page/operate/activityManage"),
  },
  {
    path: "/operate/teamAndEquity",
    name: "teamAndEquity",
    meta: {
      auth: true,
      cache: true,
      title: "团队与权益",
    },
    component: _import("page/operate/teamAndEquity"),
  },
];

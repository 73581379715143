const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const advert = [
  {
    path: '/finance/gmc',
    name: 'shoppingAd',
    meta: {
      auth: true,
      cache: true,
      title: '购物广告'
    },
    component: _import('page/advert/gmc')
  }
]

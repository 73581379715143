const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const finance = [
  {
    path: '/order/order',
    name: 'order',
    meta: {
      auth: true,
      cache: true,
      title: '订单管理'
    },
    component: _import('page/finance/order')
  },
  {
    path: '/finance/invoice',
    name: 'invoice',
    meta: {
      auth: true,
      cache: true,
      title: '发票管理'
    },
    component: _import('page/finance/invoice')
  },
  {
    path: '/finance/ad',
    name: 'ad',
    meta: {
      auth: true,
      cache: true,
      title: '广告充值'
    },
    component: _import('page/finance/ad')
  },
  {
    path: '/finance/customer',
    name: 'customer',
    meta: {
      auth: true,
      cache: true,
      title: '客户预算'
    },
    component: _import('page/finance/customer')
  },
  {
    path: '/finance/channelPartnerFlow',
    name: 'channelPartnerFlow',
    meta: {
      auth: true,
      cache: true,
      title: '渠道伙伴流水'
    },
    component: _import('page/finance/channelPartnerFlow')
  }
]

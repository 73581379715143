<template>
  <!-- <el-button type="success" @click="add" size="mini" plain>新增</el-button> -->
   <el-button type="primary" plain @click="add">新建</el-button>
</template>

<script>
export default {
  name: 'd2-button-add',
  methods: {
    add () {
      this.$emit('add')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-button-add/index.vue"
}
</vue-filename-injector>

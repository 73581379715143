import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    data: [],
    editingModule: -1,
    id: -1
  },
  mutations: {
    /**
    * 改变id
    */
    changeId (state, id) {
      state.id = id
      console.log('changeId ' + state.id)
    },
    /**
    * 改变编辑模块
    */
    changeEditingModule (state, index) {
      state.editingModule = index
    },
    /**
    * 添加模块
    */
    addModules (state, moduleData) {
      state.data.splice(state.data.length - 1, 0, moduleData)
      state.editingModule = state.data.length - 2
    },
    /**
    * 上移模块
    */
    upMoveModule (state) {
      if (
        state.editingModule > 2 &&
        state.editingModule < state.data.length - 1
      ) {
        const store = state.data[state.editingModule]
        state.data[state.editingModule] = state.data[state.editingModule - 1]
        state.data[state.editingModule - 1] = store
        state.editingModule--
      }
    },
    /**
    * 下移模块
    */
    downMoveModule (state) {
      if (
        state.editingModule > 1 &&
        state.editingModule < state.data.length - 2
      ) {
        const store = state.data[state.editingModule]
        state.data[state.editingModule] = state.data[state.editingModule + 1]
        state.data[state.editingModule + 1] = store
        state.editingModule++
      }
    },
    /**
    * 删除模块
    */
    deleteModule (state) {
      if (state.editingModule > 0) {
        state.data.splice(state.editingModule, 1)
        state.editingModule = -1
      }
    },
    /**
    * 改变整体数据
    */
    changeData (state, data) {
      state.data = data
    },
    /**
    * 改变特定index的数据
    */
    changeModuleData (state, data) {
      Vue.set(state.data, state.editingModule, {
        ...state.data[state.editingModule],
        ...data
      })
    }
  }
}

const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const goods = [
  {
    path: '/goods/goods',
    name: 'goods',
    meta: {
      auth: true,
      cache: true,
      title: '商品管理'
    },
    component: _import('page/goods/goods')
  },
  {
    path: '/goods/category',
    name: 'category',
    meta: {
      auth: true,
      cache: true,
      title: '分类管理'
    },
    component: _import('page/goods/category')
  }
]

const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const googleAds = [
  {
    path: '/mccFaAccount',
    name: 'mccFaAccount',
    meta: {
      auth: true,
      cache: true,
      title: 'MCC父账号管理'
    },
    component: _import('page/googleAds/mccFaAccount')
  },
  {
    path: '/mccSonAccount',
    name: 'mccSonAccount',
    meta: {
      auth: true,
      cache: true,
      title: 'Ads子账号管理'
    },
    component: _import('page/googleAds/mccSonAccount')
  }
]

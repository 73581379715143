<template>
    <el-dropdown size="small" class="d2-mr">
        <span class="btn-text">{{
            info.name ? `你好 ${info.name}` : "未登录"
        }}</span>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="logOff">
                <d2-icon name="power-off" class="d2-mr-5" />退出登录
            </el-dropdown-item>
        </el-dropdown-menu>
        <el-button @click="handleClick(info.name)">重置</el-button>
        <!-- 弹框 -->
    </el-dropdown>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    computed: {
        ...mapState("d2admin/user", ["info"]),
    },
    methods: {
        ...mapActions("d2admin/account", ["logout"]),

        /**
         * @description 登出
         */
        logOff() {
            this.logout({
                confirm: true,
            });
        },
        handleClick(info) {
            this.$router.push({
                name: "password",
            });
        },
    },
};
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-user/index.vue"
}
</vue-filename-injector>

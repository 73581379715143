const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const channel = [
  {
    path: '/operate/channel',
    name: 'operate.channel',
    meta: {
      auth: true,
      cache: true,
      title: '渠道商列表'
    },
    component: _import('page/channel/channel')
  },
  {
    path: '/operate/complain',
    name: 'operate.complain',
    meta: {
      auth: true,
      cache: true,
      title: '渠道审批'
    },
    component: _import('page/channel/complain')
  }
]

const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const customerManage = [
  {
    path: '/customer/list',
    name: 'customerManage',
    meta: {
      auth: true,
      cache: true,
      title: '客户列表'
    },
    component: _import('page/customerManage/customerList')
  },

]

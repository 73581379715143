const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const news = [{
    path: '/message',
    name: 'message',
    meta: {
      auth: true,
      cache: true,
      title: '消息历史'
    },
    component: _import('page/news/message')
  }, {
    path: '/messageNotification',
    name: 'messageNotification',
    meta: {
      auth: true,
      cache: true,
      title: '消息提醒'
    },
    component: _import('page/news/messageNotification')
  },
  {
    path: "/news/sendLog",
    name: "operate.sendLog",
    meta: {
      auth: true,
      cache: true,
      title: "消息发送记录",
    },
    component: _import("page/news/sendLog"),
  },
  {
    path: '/emailLog',
    name: 'emailLog',
    meta: {
      auth: true,
      cache: true,
      title: '邮件发送失败日志'
    },
    component: _import('page/news/emailLog')
  },
  {
    path: '/message/smsreply',
    name: 'smsreply',
    meta: {
      auth: true,
      cache: true,
      title: '短信回复'
    },
    component: _import('page/news/smsReply')
  }
]

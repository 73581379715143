import layoutHeaderAside from '@/layout/header-aside'
import {
  advert
} from './modules/advert.js'
import {
  news
} from './modules/news.js'
import {
  appManage
} from './modules/appManage.js'
import {
  accountManage
} from './modules/accountManage.js'
import {
  appUserManage
} from './modules/appUserManage.js'
import {
  landingPage
} from './modules/landingPage.js'
import {
  goods
} from './modules/goods.js'
import {
  finance
} from './modules/finance.js'
import {
  doctor
} from './modules/doctor.js'
import {
  googleAds
} from './modules/googleAds.js'
import {
  operate
} from './modules/operate.js'
import {
  channel
} from './modules/channel.js'
import {
  store
} from './modules/store.js'
import {
  suangtui
} from './modules/suangtui.js'
import {
  htmlEdit
} from './modules/htmlEdit.js'
import {
  customerManage
} from './modules/customerManage.js'
import {
  statistic
} from './modules/statistic.js'
import {
  partner
} from './modules/partner.js'
import {
  website
} from './modules/website.js'
// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)


/**
 * 在主框架内显示
 */
const frameIn = [{
  path: '/',
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: [
    // 首页
    {
      path: 'index',
      name: 'index',
      meta: {
        cache: true,
        auth: true
      },
      component: _import('system/index')
    },
    // 系统 前端日志
    {
      path: 'log',
      name: 'log',
      meta: {
        title: '前端日志',
        auth: true
      },
      component: _import('system/log')
    },
    {
      path: 'password',
      name: 'password',
      meta: {
        cache: true,
        title: '修改密码',
        auth: true
      },
      component: _import('system/password')
    },
    // 刷新页面 必须保留
    {
      path: 'refresh',
      name: 'refresh',
      hidden: true,
      component: _import('system/function/refresh')
    },
    // 页面重定向 必须保留
    {
      path: 'redirect/:route*',
      name: 'redirect',
      hidden: true,
      component: _import('system/function/redirect')
    },
    ...advert, // 广告模块
    ...landingPage, // 着陆页模块
    ...doctor, // 学院模块
    ...finance, // 财务模块
    ...goods, // 商品模块
    ...accountManage, // 后台账户管理
    ...appUserManage, // app用户体系
    ...appManage, // app系统管理
    ...news, // 消息管理
    ...googleAds,
    ...operate,
    ...channel,
    ...store, // 商城
    ...suangtui, // 双推
    ...htmlEdit, // html编辑器
    ...customerManage, //客户管理
    ...statistic, //数据看板
    ...partner, // 伙伴计划
    ...website, // 网站数据
  ]
}]

/**
 * 在主框架之外显示
 */
const frameOut = [
  // 登录
  {
    path: '/login',
    name: 'login',
    component: _import('system/login')
  }
]

/**
 * 错误页面
 */
const errorPage = [{
  path: '*',
  name: '404',
  component: _import('system/error/404')
}]

// 导出需要显示菜单的
export const frameInRoutes = frameIn

// 重新组织后导出
export default [
  ...frameIn,
  ...frameOut,
  ...errorPage
]
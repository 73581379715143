const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const accountManage = [
  {
    path: '/rule/admin',
    name: 'account',
    meta: {
      auth: true,
      cache: true,
      title: '账号管理'
    },
    component: _import('page/accountManage/accountManagment')
  },
  {
    path: '/rule/role',
    name: 'role',
    meta: {
      auth: true,
      cache: true,
      title: '角色管理'
    },
    component: _import('page/accountManage/role')
  },
  {
    path: '/menu/route',
    name: 'route',
    meta: {
      auth: true,
      cache: true,
      title: '菜单管理'
    },
    component: _import('page/accountManage/route')
  }
]

const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const statistic = [{
        path: "/statistic/personalStatistic",
        name: "personalStatistic",
        meta: {
            auth: true,
            cache: true,
            title: "个人",
        },
        component: _import("page/statistic/personalStatistic"),
    },
    {
        path: "/statistic/teamStatistic",
        name: "teamStatistic",
        meta: {
            auth: true,
            cache: true,
            title: "团队",
        },
        component: _import("page/statistic/teamStatistic"),
    },
    {
        path: "/statistic/monthData",
        name: "monthData",
        meta: {
            auth: true,
            cache: true,
            title: "月活数据",
        },
        component: _import("page/statistic/monthData"),
    },

];
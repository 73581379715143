const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const website = [
    {
        path: '/website/data',
        name: 'websiteData',
        meta: {
            auth: true,
            cache: true,
            title: '网站数据'
        },
        component: _import('page/website/index')
    },
    {
        path: '/user/website',
        name: 'userWebsite',
        meta: {
            auth: true,
            cache: true,
            title: '用户与网站'
        },
        component: _import('page/website/user')
    }
]

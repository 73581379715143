const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const doctor = [
  {
    path: '/doctor/comment',
    name: 'comment',
    meta: {
      auth: true,
      title: '留言管理'
    },
    component: _import('page/doctor/comment')
  },
  {
    path: '/doctor/trainingCamp',
    name: 'trainingCamp',
    meta: {
      auth: true,
      title: '特训营报名'
    },
    component: _import('page/doctor/trainingCamp')
  },
  {
    path: '/doctor/joinIncubationCamp',
    name: 'joinIncubationCamp',
    meta: {
      auth: true,
      title: '孵化营入营申请'
    },
    component: _import('page/doctor/joinIncubationCamp')
  },
  {
    path: '/doctor/course',
    name: 'course',
    meta: {
      auth: true,
      title: '课程管理'
    },
    component: _import('page/doctor/course')
  },
  {
    path: '/doctor/series',
    name: 'series',
    meta: {
      auth: true,
      title: '系列管理'
    },
    component: _import('page/doctor/series')
  },
  {
    path: '/doctor/lecturer',
    name: 'lecturer',
    meta: {
      auth: true,
      title: '师资管理'
    },
    component: _import('page/doctor/lecturer')
  },
  {
    path: '/doctor/addCourse',
    name: 'addCourse',
    meta: {
      auth: true,
      title: '添加课程'
    },
    component: _import('page/doctor/addCourse')
  },
  {
    path: '/doctor/pageManagement',
    name: 'pageManagement',
    meta: {
      auth: true,
      title: '页面管理'
    },
    component: _import('page/doctor/pageManagement')
  },
  {
    path: '/doctor/marketingComponentManagement',
    name: 'marketingComponentManagement',
    meta: {
      auth: true,
      title: '营销组件管理'
    },
    component: _import('page/doctor/marketingComponentManagement')
  }
]

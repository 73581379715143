const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const htmlEdit = [
    {
        path: '/operate/htmlManagement/editor',
        name: 'Editor',
        meta: {
            auth: true,
            cache: true,
            title: "活动编辑器",
          },
        component: () => import('../../htmlEdit/src/vue-editor/views/editor/Editor.vue'),
    },
    {
        path: '/operate/htmlManagement/editor-m',
        name: 'editorM',
        meta: {
            auth: true,
            cache: true,
            title: "活动编辑器（手机端）",
          },
        component: () => import('../../htmlEdit/src/vue-editor/views/editor/EditorM.vue'),
    },
    {
      path: '/operate/htmlManagement/schemaEditor',
      name: 'schemaEditor',
      meta: {
          auth: true,
          cache: true,
          title: "表单schema编辑器",
        },
      component: () => import('../../htmlEdit/src/schema-generator/views/editor/Editor.vue'),
    },
    {
      path: '/operate/htmlManagement/previewSchemaEditor',
      name: 'previewSchemaEditor',
      meta: {
          auth: true,
          cache: true,
          title: "预览",
        },
      component: () => import('../../htmlEdit/src/schema-generator/views/editor/previewSchemaEditor.vue'),
    },
]

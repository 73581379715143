<template>
  <el-button icon="el-icon-refresh" type="success" @click="onclick" size="mini" plain></el-button>
</template>

<script>
export default {
  name: 'd2-button',
  methods: {
    onclick () {
      this.$emit('onclick')
    }
  }
}
</script>

<style lang="scss" scoped>
.el-button {
  float: right;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-button/index.vue"
}
</vue-filename-injector>

const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const partner = [{
    path: "/partner/approve",
    name: "approve",
    meta: {
        auth: true,
        cache: true,
        title: "伙伴审批",
    },
    component: _import("page/partner/approve"),
},
{
    path: "/partner/channelPartner",
    name: "channelPartner",
    meta: {
        auth: true,
        cache: true,
        title: "渠道伙伴",
    },
    component: _import("page/partner/channelPartner"),
},
{
    path: "/partner/channelPartnerDetail",
    name: "channelPartnerDetail",
    meta: {
        auth: true,
        cache: true,
        title: "渠道伙伴详情",
    },
    component: _import("page/partner/channelPartnerDetail"),
}
];
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const landingPage = [
  {
    path: '/iron',
    name: 'pageList',
    meta: {
      auth: true,
      cache: true,
      title: '着陆页管理(老)'
    },
    component: _import('page/landingPage/pageList')
  },
  {
    path: '/pagePro',
    name: 'pagePro',
    meta: {
      auth: true,
      cache: true,
      title: '着陆页管理(新)'
    },
    component: _import('page/landingPage/pagePro')
  },
  {
    path: '/inquiryEmail',
    name: 'inquiryEmail',
    meta: {
      auth: true,
      cache: true,
      title: '询盘'
    },
    component: _import('page/landingPage/inquiryEmail')
  },
  {
    path: '/material',
    name: 'material',
    meta: {
      auth: true,
      cache: true,
      title: '素材准备'
    },
    component: _import('page/landingPage/material')
  },
  {
    path: '/tagManagment',
    name: 'tagManagment',
    meta: {
      auth: true,
      cache: true,
      title: '标签管理'
    },
    component: _import('page/landingPage/tagManagment')
  },
  {
    path: '/moduleManagment',
    name: 'moduleManagment',
    meta: {
      auth: true,
      cache: true,
      title: '模版管理'
    },
    component: _import('page/landingPage/moduleManagment')
  },
  {
    path: '/numberManagement',
    name: 'numberManagement',
    meta: {
      auth: true,
      cache: true,
      title: '个数管理'
    },
    component: _import('page/landingPage/numberManagement')
  }
]

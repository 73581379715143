const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export const suangtui = [
    {
        path: '/suangtui/suangtui',
        name: 'suangtui',
        meta: {
            auth: true,
            cache: true,
            title: '双推统计'
        },
        component: _import('page/suangtui/suangtui')
    }
]

// Vue
import Vue from 'vue'
import App from './App'
// 核心插件
import d2Admin from '@/plugin/d2admin'
// store
import store from '@/store/index'
// 中央事件总线（事件中心）
import eventBus from './plugin/eventBus/index.js'
// [ 可选组件 ]D2-Crud
import D2Crud from '@d2-projects/d2-crud'
// [ 可选组件 ] 图表
import VCharts from 'v-charts'
// [ 可选组件 ] 右键菜单
import contentmenu from 'v-contextmenu'
import 'v-contextmenu/dist/index.css'
// [ 可选组件 ] JSON 树状视图
import vueJsonTreeView from 'vue-json-tree-view'
// [ 可选组件 ] 网格布局
import { GridLayout, GridItem } from 'vue-grid-layout'
// [ 可选组件 ] 区域划分
import SplitPane from 'vue-splitpane'
// [ 可选组件 ] UEditor
import VueUeditorWrap from 'vue-ueditor-wrap'
// [ 可选插件 ] 数据导入导出
import pluginExport from '@d2-projects/vue-table-export'
import pluginImport from '@d2-projects/vue-table-import'
// 分页组件
// import pagination from './components/d2-pagination'
// 刷新按钮组件
import button from './components/d2-button'
// 新建按钮组件
import Button from './components/d2-button-add'
// 菜单和路由设置
import router from './router'
// import { menuHeader, menuAside } from '@/menu'
import { frameInRoutes } from '@/router/routes'
import { Ajax } from '@api/getApi.js'
import util from './libs/util'
import VueAreaLinkage from 'vue-area-linkage';
import 'vue-area-linkage/dist/index.css';

import moment from 'moment'
Vue.prototype.$moment = moment;

Vue.use(VueAreaLinkage);
// 核心插件
Vue.use(d2Admin)

// 可选插件组件
Vue.use(D2Crud)
Vue.use(VCharts)
Vue.use(contentmenu)
Vue.use(vueJsonTreeView)
Vue.use(pluginExport)
Vue.use(pluginImport)
Vue.use(eventBus);
Vue.component('d2-grid-layout', GridLayout)
Vue.component('d2-grid-item', GridItem)
Vue.component('SplitPane', SplitPane)
Vue.component('VueUeditorWrap', VueUeditorWrap)
Vue.component('d2-button', button)
Vue.component('d2-button-add', Button)
// Vue.component('d2-paginnation', pagination)
let app = new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    // console.log(util.cookies.get('uuid'))
    if (util.cookies.get('uuid')) {
      Ajax({ // 每次进入都要获取侧栏
        methods: 'get',
        url: '/rule/menu'
      }, (res) => {
        // console.log('menu');

        // console.log(JSON.stringify(res, null, 4));
        this.$store.commit('d2admin/menu/asideSet', this.changeData(res.data))
      })
    }
    // 处理路由 得到每一级的路由设置
    this.$store.commit('d2admin/page/init', frameInRoutes)
    // 设置顶栏菜单
    // this.$store.commit('d2admin/menu/headerSet', menuHeader)
    // 初始化菜单搜索功能
    // this.$store.commit('d2admin/search/init', menuHeader)
  },
  mounted() {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow')
    // 用户登录后从数据库加载一系列的设置
    this.$store.dispatch('d2admin/account/load')
    // 获取并记录用户 UA
    this.$store.commit('d2admin/ua/get')
    // 初始化全屏监听
    this.$store.dispatch('d2admin/fullscreen/listen')
  },
  methods: {
    changeData(obj) {
      return obj.map(each => {
        if (typeof each === 'object') {
          each.title = each.name
          if (typeof each.children === 'object' && each.children.length !== 0) {
            each.children = this.changeData(each.children)
          }
          return each
        }
      })
    }
  }
  // watch: {
  //   // 检测路由变化切换侧边栏内容
  //   '$route.matched': {
  //     handler (matched) {
  //       if (matched.length > 0) {
  //         const _side = menuAside.filter(menu => menu.path === matched[0].path)
  //         this.$store.commit('d2admin/menu/asideSet', _side.length > 0 ? _side[0].children : [])
  //       }
  //     },
  //     immediate: true
  //   }
  // }
}).$mount('#app')
export default app
